<template>
  <button class="btn purple" @click="openFixedModal('FORM_RIT_PASSAGIERS', { rit_id, acties: false })">
    <i class="fas fa-tasks"></i>
    Passagiers checklist
  </button>
</template>

<script setup>
import { defineProps } from 'vue'
import useModal from '@/hooks/useModal'

defineProps({
  rit_id: Number,
})

const [openFixedModal] = useModal({ fullWidth: true, fixed: true })
</script>
